body {
  font-family: sans-serif;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  position: absolute !important;
  border: 1px solid rgb(232, 232, 232) !important;
  border-top-width: 0 !important;
  list-style: none !important;
  margin-top: 0 !important;
  max-height: 170px !important;
  overflow-y: auto !important;
  padding-left: 0 !important;
  width: 100% !important;
  z-index: 9999 !important;
  background: #fff;
}

.suggestions-medicine{
  position: absolute !important;
  border: 1px solid rgb(232, 232, 232) !important;
  border-top-width: 0 !important;
  list-style: none !important;
  margin-top: 0 !important;
  max-height: 170px !important;
  overflow-y: auto !important;
  padding-left: 0 !important;
  width: 30% !important;
  z-index: 9999 !important;
  background: #fff;
}

.suggestions li {
  padding: 0.5rem !important;
  font-size: 12px !important
}

.suggestion-active,
.suggestions li:hover {
  background-color: #e4e4e4 !important;
  color: #3a3a3a !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 12px !important
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid rgb(238, 238, 238);
}