html,
body,
#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
}

.year {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.month {
  margin: 5px 35px 8px 35px;
}

.month-name {
  color: #70757a;
  font-weight: bold;
  margin-left: 10px;
  font-size: 14px;
}

.day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 500;
  color: gray;
  font-size: 90%
}

.date {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50px;
  border: none;
  outline: none;
}

.date.in-month:hover {
  cursor: pointer;
  background: #eaeaea;
  color: #000;
}

.today {
  background: #2196f3;
  color: #fff;
}

.date.prev-month,
.date.next-month {
  color: grey;
}
