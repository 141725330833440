.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 7px;
    background: #f3f3f3;
    padding-bottom: 40px
  }

  .upload-dark{
    background: #260931 !important;
    border: 1px solid #303030 !important
  }

  .button-dark{
    background: #424242 !important;
    border: 1px solid #424242 !important
  }
  
  .Content {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 10px
  }
  
  .Files {
    margin-left: 8px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
  }
  
  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 15px;
  }
  
  .Title {
    margin-bottom: 32px;
    color: #555;
  }