.ProseMirror {
  padding: 20px;
  border: 1px solid #eaeaea;
  margin-top: 15px;
  border-radius: 10px;
  background: #eaeaea
}

.promo-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 8px;
  align-items: center;
  padding: 20px;
  border: 1px solid #ffb74d;
  color: white;
  border-radius: 10px;
  background: #ffb74d;
  font-size: 1.5em;
  font-weight: bold;

  .promo-card__price {
    text-decoration: line-through;
    text-align: end;
  }

  .promo-card__pad {
    text-align: end;
  }

  .promo-card__desc {
    grid-column: 1 / -1;
    grid-row: 3 / 3;
    overflow-wrap: break-word;
    font-size: 18px;
  }

  .promo-card__button {
    border: 1px solid transparent;
    color: #ffb74d;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    grid-column: 1 / -1;
    grid-row: 4 / 4;
    justify-self: end;
  }

}

.replace-text {
  background: lightgray;
  padding: 3px 6px;
  font-weight: bold;
  border-radius: 6px;
}

tr.meeting-denied th {
  color: #D14343;
}